<template>
  <v-container class="listUtenti">
    <v-data-iterator
      :items="utenti"
      :items-per-page.sync="itemsPerPage"
      :page="page"
      :search="search"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar
          color="secondary"
          class="mb-5"
        >
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            background-color="white"
            label="Cerca"
            class="blackTextWriting"
          >
            <template v-slot:prepend-inner>
              <v-icon small>fas fa-search</v-icon>
            </template>
          </v-text-field>
          <v-btn
            class="ml-2"
            v-if="!$vuetify.breakpoint.mdAndUp"
            color="primary"
            fab
            small
            depressed
            to="/newUtente"
          >
            <v-icon small>fas fa-plus</v-icon>
          </v-btn>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer v-if="ruolo == 'Master' || ruolo == 'Tecnico'"></v-spacer>
              <v-select
                  v-if="ruolo == 'Master' || ruolo == 'Tecnico'"
                  :items="lista_permessi"
                  v-model="permesso"
                  flat
                  solo-inverted
                  background-color="white"
                  hide-details
                  label="Ruolo"
                  class="blackTextWriting"
              >
                <template v-slot:prepend-inner>
                  <v-icon small>fas fa-filter</v-icon>
                </template>
              </v-select>
            <v-spacer></v-spacer>
            <v-select
              v-model="sortBy"
              flat
              solo-inverted
              hide-details
              background-color="white"
              :items="keys"
              label="Ordina Per"
              class="blackTextWriting"
            >
              <template v-slot:prepend-inner>
                <v-icon small>fas fa-sort</v-icon>
              </template>
            </v-select>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-btn-toggle
              v-model="sortDesc"
              mandatory
            >
              <v-btn
                large
                depressed
                color="primary"
                :value="false"
              >
                <v-icon small color="white">fas fa-chevron-up</v-icon>
              </v-btn>
              <v-btn
                large
                depressed
                color="primary"
                :value="true"
              >
                <v-icon small color="white">fas fa-chevron-down</v-icon>
              </v-btn>
            </v-btn-toggle>
            <v-btn
              class="ml-2"
              color="primary"
              fab
              small
              depressed
              to="/newUtente"
            >
              <v-icon small>fas fa-plus</v-icon>
            </v-btn>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item._id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card :to="'/schedaUtente?_id='+item._id">
              <v-card-title class="subheading font-weight-bold lightGrey">
                {{ item.nominativo1 }}
              </v-card-title>

              <v-divider></v-divider>

              <v-list dense>
                <v-list-item
                  v-for="(key, index) in filteredKeys"
                  :key="index"
                >
                  <v-list-item-content :class="sortBy === key ? 'secondary--text bold' : 'bold'">
                    <small>{{ capitalize(key) }}</small>
                  </v-list-item-content>
                  <v-list-item-content
                    class="align-end"
                    :class="{ 'secondary--text bold--text': sortBy === key }"
                  >
                    <div class="trimTextTooLong">{{ item[key.toLowerCase()] }}</div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row
          class="mt-2"
          align="center"
          justify="center"
        >

          <v-spacer></v-spacer>

          <span
            class="mr-4
            grey--text"
          >
            Pagina {{ page }} di {{ numberOfPages }}
          </span>
          <v-btn
            fab
            small
            color="primary"
            class="mr-1"
            @click="formerPage"
          >
            <v-icon small>fas fa-chevron-left</v-icon>
          </v-btn>
          <v-btn
            fab
            small
            color="primary"
            class="ml-1"
            @click="nextPage"
          >
            <v-icon small>fas fa-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import apiUtenti from '@/js/pages/utenti';
const FiltriLista = () => import('@/components/FiltriLista');

export default {
  name: 'listUtenti',
  components: {
    FiltriLista
  },
  data() {
    return {
      utenti: [],
      token: this.$cookies.get('token'),
      ruolo: this.$store.state.ruolo,
      search: '',
      filter: {},
      sortDesc: true,
      page: 1,
      itemsPerPage: 10,
      sortBy: 'data_creazione',
      keys: [
        'nominativo1',
        'nominativo2',
        'telefono',
        'email',
        'ruolo',
        'partitaIva',
      ],
      lista_permessi: ['Tutti', 'Master', 'Tecnico', 'Partner', 'Cliente'],
      permesso: undefined,
    }
  },
  computed: {
    numberOfPages () {
      return Math.ceil(this.utenti.length / this.itemsPerPage)
    },
    filteredKeys () {
      return this.keys.filter(key => key !== 'Name')
    },
  },
  watch: {
    async permesso() {
      if(
        this.permesso && 
        this.permesso !== 'Tutti'
      ) this.utenti = await apiUtenti.getAll(this.token, this.permesso);
      else this.utenti = await apiUtenti.getAll(this.token);
    }
  },
  async created() {
    if(this.ruolo == 'Partner') this.utenti = await apiUtenti.getAllPartner(this.token, 'Baggi');
    else this.utenti = await apiUtenti.getAll(this.token);
  },
  methods: {
    capitalize(string) {
      if(string) return string.charAt(0).toUpperCase() + string.slice(1);
      else return '';
    },
    viewUtente(id) {
      this.$router.push('/schedaUtente?_id='+id)
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    },
  },
}
</script>